import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { AuthenticationService } from '../services/authentication.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class JwtInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let currentUser = localStorage.getItem("token");
        request = request.clone({
            setHeaders: {
                
            }
        })
        if (currentUser ) {
            request = request.clone({
                setHeaders: {
                    "Authorization": `Bearer ${currentUser}`
                }
            });
        }

        

        return next.handle(request).pipe( map( val => val ), catchError(error => {
            if(error instanceof HttpErrorResponse && error.status === 401){
                this.authenticationService.logout();
                this.router.navigate(['login']);
            }
            
            return throwError(error);
        }));

        
    }
}