import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from '../interfaces/user.type';

const USER_AUTH_API_URL = 'auth/signin';
const httpOptions = {
    headers: new HttpHeaders({ 
      'Access-Control-Allow-Origin':'*',
      'Content-Type':'application/json',
      'operationsystem': environment.operationsystem,
      'publicip': environment.ip ,
      'uniqueidentifier':'AdminTribune',
      'devicename':'Admin',
    })
};
  
@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {
    // private currentUserSubject: BehaviorSubject<User>;
    // public currentUser: Observable<User>;

    constructor(
        private http: HttpClient,
        ) {
        // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        // this.currentUser = this.currentUserSubject.asObservable();
    }

    // public get currentUserValue(): User {
    //     return this.currentUserSubject.value;
    // }

    login( body: any ) {
        return this.http.post<any>(environment.api + USER_AUTH_API_URL, body, httpOptions )
        .pipe(map(user => {
            if (user) {
                localStorage.setItem('token', user.token);
                localStorage.setItem('refreshToken', user.refreshToken);
                // this.currentUserSubject.next(user);
            }
            return user;
        }));
    }

    logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        
        // this.currentUserSubject.next(null);
    }
}