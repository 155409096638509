import { Component, OnInit  } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
    isAdBlock : boolean = false;
    ip: string = localStorage.getItem("myip") ?? "";

    constructor(
        private modalService: NzModalService
    ){
        
    }

    ngOnInit()  
    {  

    }  


    showModalError(){
        this.modalService.error({
            nzMaskClosable : false,
            nzContent: 'Debe desactivar la estension AdBlock, para continuar',
            nzTitle: 'Error',
            nzOnOk: () => {
                
            },
            nzCloseOnNavigation: false,
            nzKeyboard: false,
            nzClosable: false,
            nzOkText: "Verificar"
        });
    }
}
