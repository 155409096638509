import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
    {
        path: 'dashboard/home',
        title: 'Dashboard',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'dashboard',
        submenu: []
    },
    {
        path: '',
        title: 'Mantenimientos',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'appstore',
        submenu: [
            { 
                path: 'maintenance/users',
                title: 'Usuarios', 
                iconType: '', 
                icon: '',
                iconTheme: '',
                submenu: [] 
            },
            { 
                path: 'maintenance/posts',
                title: 'Publicaciones', 
                iconType: '', 
                icon: '',
                iconTheme: '',
                submenu: [] 
            },
            { 
                path: 'maintenance/posts/block',
                title: 'Publicaciones Denunciadas', 
                iconType: '', 
                icon: '',
                iconTheme: '',
                submenu: [] 
            }
        ]
    },
    {
        path: '',
        title: 'Centro de bots',
        iconType: 'nzIcon',
        iconTheme: 'outline',
        icon: 'user-switch',
        submenu: [
            { 
                path: 'bots/list',
                title: 'Lista bots', 
                iconType: '', 
                icon: '',
                iconTheme: '',
                submenu: [] 
            },
            { 
                path: 'bots/posts',
                title: 'Publicar bots', 
                iconType: '', 
                icon: '',
                iconTheme: '',
                submenu: [] 
            },
            { 
                path: 'bots/coments',
                title: 'Comentar con bots', 
                iconType: '', 
                icon: '',
                iconTheme: '',
                submenu: [] 
            }
        ]
    }
]    