<div class="header">
    <div class="logo logo-dark">
        <a href="">
            <img src="assets/images/logo/logo.png" alt="Logo">
            
        </a>
    </div>
    <div class="logo logo-white">
        
    </div>
    <div class="nav-wrap">
        <ul class="nav-left">
            <li class="desktop-toggle">
                <a (click)="toggleFold()">
                    <i nz-icon [nzType]="isFolded? 'menu-unfold':'menu-fold'" theme="outline"></i>
                </a>
            </li>
            <li class="mobile-toggle">
                <a (click)="toggleExpand()">
                    <i nz-icon [nzType]="isExpand? 'menu-fold': 'menu-unfold'" theme="outline"></i>
                </a>
            </li>
        </ul>
        <ul class="nav-right">
            <li>
                <a (click)="logout()">
                    <nz-avatar [nzSize]="64" nzIcon="user"></nz-avatar>
                </a>
                
            </li>
        </ul>
    </div>
</div>    