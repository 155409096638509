import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/authentication.service';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html'
})

export class HeaderComponent{

    searchVisible : boolean = false;
    quickViewVisible : boolean = false;
    isFolded : boolean;
    isExpand : boolean;

    constructor( private themeService: ThemeConstantService, private router: Router, private authenticationService: AuthenticationService, private modalService: NzModalService) {}

    ngOnInit(): void {
        this.themeService.isMenuFoldedChanges.subscribe(isFolded => this.isFolded = isFolded);
        this.themeService.isExpandChanges.subscribe(isExpand => this.isExpand = isExpand);
    }

    toggleFold() {
        this.isFolded = !this.isFolded;
        this.themeService.toggleFold(this.isFolded);
    }

    toggleExpand() {
        this.isFolded = false;
        this.isExpand = !this.isExpand;
        this.themeService.toggleExpand(this.isExpand);
        this.themeService.toggleFold(this.isFolded);
    }

    searchToggle(): void {
        this.searchVisible = !this.searchVisible;
    }

    logout(){
        this.modalService.confirm({
            nzMaskClosable : false,
            nzContent: 'Debe cerrar sesión?',
            nzTitle: 'Cerrar Sesión',
            nzOnOk: () => {
                this.authenticationService.logout();
                this.router.navigate(['login']);
            },
            nzOnCancel: () => {
                this.modalService.closeAll();
            },
            nzCloseOnNavigation: false,
            nzKeyboard: false,
            nzClosable: false,
            nzOkText: "Aceptar",
            nzCancelText: "Cancelar"
        })
        
    }
}
