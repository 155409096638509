import { Routes } from '@angular/router';

export const CommonLayout_ROUTES: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule),
    } ,
    {
        path: 'maintenance',
        loadChildren: () => import('../../maintenance/maintenance.module').then(m => m.MaintenanceModule),
    },
    {
        path: 'bots',
        loadChildren: () => import('../../bots/bots.module').then(m => m.BotsModule),
    } 
];